.locations {
    h2 {
        margin: 70px 0 30px 0;
    }
    .accordion {
        background: #fff;
        list-style-type: none;
        margin: 0;
        padding: 0 0 105px 0;
        .accordion-item {
            border: 1px solid #5C2946;
            border-radius: 39px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
            margin-bottom: 11px;
            padding: 25px 30px;
            .accordion-title {
                border: none;
                border-bottom: 0;
                color: $highlight;
                display: block;
                font-family: 'tg', sans-serif;
                font-size: 32px;
                position: relative;
                padding: 0;
                text-transform: uppercase;
                &:focus,
                &:hover {
                    background-color: transparent;
                }
                &:before {
                    content: url(../img/icons/accordion-arrow.svg);
                    margin-top: -.5rem;
                    position: absolute;
                    right: 0;
                    top: 15px;
                    transform: rotate(180deg);
                }
                .accordion--extra-message {
                    color: #a5a4a8;
                    font-family: 'tg-bold2', sans-serif;
                    font-size: 24px;
                    padding-top: 5px;
                    float: none;
                    display: block;
                    margin-right: 30px;
                    @media (min-width: 40em) {
                      float: right;
                    }
                }
            }
            &.is-active {
                box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
                > .accordion-title:before {
                    content: url(../img/icons/accordion-arrow.svg);
                    transform: rotate(0deg);
                    margin-top: -1.2rem;
                }
            }
            .accordion-content {
                border: none;
                display: none;
                border-bottom: 0;
                background-color: transparent;
                color: $primary;
                padding: 24px 0 0 0;
            }
            &:first-child > :first-child {
                border-radius: 0;
            }
        }
        &__visit,
        &__call,
        &__hours {
            font-family: 'tg-light', sans-serif;
            h3 {
                background-position: 0 0;
                background-repeat: no-repeat;
                color: $highlight;
                font-family: 'tg-bold2', sans-serif;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 1px;
                //line-height: 17px;
                margin-bottom: 3px;
                padding-left: 30px;
                text-transform: uppercase;
            }
        }
        &__visit {
            margin-bottom: 30px;
            padding-right: 30px;
            h3 {
                background-image: url(../img/icons/map-pin.svg);
            }
            &--title,
            &--address,
            &--address2 {
                margin: 0;
            }
        }
        &__call {
            margin-bottom: 30px;
            padding-right: 30px;
            h3 {
                background-image: url(../img/icons/call.svg);
            }
            &--number {
                margin: 0;
            }
        }
        &__hours {
            padding-right: 30px;
            h3 {
                background-image: url(../img/icons/clock.svg);
            }
            table {
                tr {
                    td {
                        line-height: 16px;
                        padding-right: 20px;
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        &__map {
            margin-bottom: 27px;
            position: relative;
            .overlay {
                align-items: center;
                border: 0;
                bottom: 0;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                div {
                    text-align: center;
                    img {
                        display: inline-block;
                    }
                    p {
                        color: $highlight;
                        display: block;
                        font-family: 'tg-bold2', sans-serif;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        &__link {
            text-align: right;
        }
    }
}
