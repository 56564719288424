.cta {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10px;
    min-height: 130px;
    padding-top: 116px;
    position: relative;
    width: 100%;
    &:before {
        background: linear-gradient(58.92deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.7) 100%);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
    .wrapper {
        padding-bottom: 56px;
        position: relative;
        h2 {
            color: #fff;
            font-family: 'tg-bold-ex', sans-serif;
            font-size: 24px;
            letter-spacing: 0.5px;
            line-height: 30px;
            margin-bottom: 15px;
        }
        .medium-10 {
            text-align: right;
        }
    }
}




// >= 640 (Foundation == Medium)
@media (min-width: 40em) {
    .cta {
        .wrapper {
            h2 {
                font-size: 60px;
                line-height: 66px;
            }
        }
    }
}
