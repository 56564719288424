footer {
    font-size: 1rem;
    margin: 0 auto;
    padding: 90px 0 50px 0;
    text-align: center;
    a {
        color: $highlight;

                font-family: 'tg-bold2', sans-serif;
        text-decoration: none;
        text-transform: uppercase;
    }
    .menu,
    .social ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 1rem;
            display: inline-block;
            padding: 0 10px;
        }
    }
    .menu {
        border-bottom: 1px #d3d1d3 solid;
        padding-bottom: 31.5px;
        li {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .offsite-links {
        padding-top: 31.5px;
    }
    .gift-cards {
        margin-bottom: 1rem;
    }
}
.sub-footer {
    background-color: $primary;
    font-family: 'tg-light', sans-serif;
    .wrapper {
        padding: 25px 0;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            color: #A5A4A8;
            font-size: 12px;
            font-weight: 300;
            line-height: 14px;
            text-align: center;
        }
    }
}

// >= 640 (Foundation == Medium)
@media (min-width: 40em) {
    footer {
        text-align: left;
        .menu,
        .social ul {
            li {
                display: inline-block;
            }
        }
        .menu,
        .offsite-links {
            display: flex;
            justify-content: space-between;
        }
        .menu {
            li {
                margin-bottom: 0;
            }
        }
        .social ul {
            li {
                margin: 0 2rem;
            }
        }
    }

    .sub-footer {
        ul {
            display: flex;
            justify-content: space-between;
            li {
                display: inline-block;
            }
        }
    }
}
