span.wpcf7-list-item {
    display: inline-block;
    margin: 0 16px 0 0;
}
ul.good-stuff {
    span.wpcf7-list-item {
        display: inline-block;
        margin: 0 0 0 0;
    }
}

.careers-form,
.contact-form {
    color: $primary;
    font-family: 'tg', sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    hr {
        background-color: $highlight;
        border: 0;
        height: 1px;
    }
    .no-margin {
        margin-bottom: 0;
        margin-top: 0;
    }
    h2 {
        color: $highlight;
        font-family: 'tg-bold', sans-serif;
        font-size: 32px;
        letter-spacing: 0.5px;
        line-height: 32px;
        margin: 48px 0 0 0;
        text-transform: uppercase;
    }
    h3 {
        color: $highlight;
        font-family: 'tg-bold', sans-serif;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    label,
    legend {
        color: #A5A4A8;
        font-family: 'tg-bold', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        margin-bottom: 18px;
        margin-top: 18px;
        text-transform: uppercase;
    }
    input[type="text"],
    input[type="email"],
    textarea {
        background-color: #F5F5F5;
        border: none;
        box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.1);
        height: 42px;
        margin: 8px 0 24px 0;
        padding-left: 12px;
        width: 100%;
    }
    textarea {
        margin: 8px 0 0 0;
        min-height: 150px;
        padding-top: 12px;
    }
    select {
        appearance: none;
        background-color: #F5F5F5;
        border: none;
        height: 42px;
        padding-left: 12px;
        width: 100%;
    }
    input[type="checkbox"] {
        appearance: none;
        border: 1px solid #A5A4A8;
        height: 16px;
        margin-right: 9px;
        position: relative;
        vertical-align: top;
        width: 16px;
        &:checked:after {
            content: '•';
            position: absolute;
            top: -8px;
            left: 0;
            font-size: 26px;
        }
    }
    input[type="radio"] {
        appearance: none;
        border: 1px solid #A5A4A8;
        height: 17px;
        margin-right: 7px;
        position: relative;
        vertical-align: top;
        width: 17px;
        &:checked:after {
            content: '•';
            position: absolute;
            top: -8px;
            left: 0;
            font-size: 26px;
        }
    }
    input[type="file"] {
        margin-top: 13px;
    }
    input[type="submit"] {
        margin-top: 52px;
    }
    fieldset {
        border: none;
        padding: 0;
        div {
            display: inline-block;
            margin-right: 0;
        }
        ul {
            columns: 1;
            column-gap: 25px;
            list-style-type: none;
            margin: 0 0 32px 0;
            padding: 0;
            li {
                break-inside: avoid;
                margin-bottom: .5rem;
            }
        }
    }
}



.contact-form {
    label {
        color: $highlight;
        margin: 15px 0 8px 20px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        background-color: transparent;
        border: 1px $highlight solid;
        border-radius: 26px;
        box-shadow: none;
        height: 53px;
        margin: 8px 0 15px 0;
        padding-left: 12px;
        width: 100%;
    }
    input[type="submit"] {
        margin-top: 32px;
    }
}

// >= 640 (Foundation == Medium)
@media (min-width: 40em) {
    .careers-form {
        fieldset {
            ul {
                columns: 2;
            }
        }
    }
}

// >= 768
@media (min-width: 48em) {
    .careers-form {
        fieldset {
            ul {
                columns: 3;
            }
        }
    }
}

// >= 1024 (Foundation == Large)
@media (min-width: 64em) {
    .careers-form {
        fieldset {
            ul {
                columns: 4;
            }
        }
    }
}

// >= 1200
@media (min-width: 75em) {
    .careers-form {
        fieldset {
            ul {
                columns: 5;
            }
        }
    }
}
