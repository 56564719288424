.careers {
    //h2 {
    //    margin: 0 0 12px 0;
    //}
    &__intro {
        background-color: #F5F5F5;
        margin: 68px 0 20px 0;
        padding: 52px 100px;
    }
}
