* {
    box-sizing: border-box;
}

html {
    font-size: $rem-base;
    scroll-behavior: smooth;
}

body {
    color: $primary;
    font-family: 'tg-light', sans-serif;
    font-size: $rem-base;
    font-weight: 300;
    margin: 0;
}

a,
button,
input,
textarea,
select,
option {
    outline: none;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {margin: 0;}

h1 {
    color: #fff;
    font-family: 'tg-bold-ex';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 30px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

h2 {
    color: $highlight;
    font-family: 'tg-bold';
    font-size: 64px;
    letter-spacing: 1px;
    line-height: 70px;
    text-transform: uppercase;
}

.wrapper {
    margin: 0 auto;
    max-width: 1200px;
}

.responsive {
    height: auto;
    max-width: 100%;
}

.button {
    border: 1px $highlight solid;
    border-radius: 30px;
    background-color: $highlight;
    color: #fff;
    display: inline-block;
    font-family: 'tg-bold', sans-serif;
    font-size: 20px;
    height: 60px;
    letter-spacing: 2.5px;
    padding: 16px 75px;
    text-transform: uppercase;
    transition: background-color .15s linear;
    &:hover {
        background-color: rgba($highlight, 0.6);
    }
}


// >= 640 (Foundation == Medium)
@media (min-width: 40em) {

    h1 {
        font-size: 60px;
        line-height: 66px;
    }

}
