.locations-selector {
    background: linear-gradient(180deg, #D2D1D3 0%, #FFFFFF 100%);
    padding: 36px 0 18px 0;
    width: 100%;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 5px;
            position: relative;
            &.active,
            &:hover {
                a:after {
                    background-color: $highlight;
                    bottom: 0px;
                    content: '';
                    left: 0;
                    position: absolute;
                    height: 4px;
                    width: 100%;
                }
            }
            a {
                color: $highlight;
                display: inline-block;
                font-family: 'tg-bold', sans-serif;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 20px;
                padding: 5px;
                position: relative;
                text-transform: uppercase;
            }
        }
    }
}

.team {
    padding-top: 68px;
    .team-info {
      padding: 2em;
      @media (min-width: 40em) {
        padding: 0;
      }
    }
    &__name {
      font-size: 44px;
      @media (min-width: 40em) {
        font-size: 64px;
      }
    }
    &__title {
        color: #A5A4A8;
        font-family: 'tg-bold-ex', sans-serif;
        font-size: 18px;
        letter-spacing: 0.15px;
        line-height: 24px;
        margin: 0;
        text-transform: uppercase;
    }
    &__since,
    &__hometown,
    &__favorite-thing,
    &__spare-time {
        font-family: 'tg', sans-serif;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 32px;
        &--headline,
        span {
            color: $highlight;
            font-family: 'tg-bold';
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 20px;
            padding-right: 16px;
            text-transform: uppercase;
        }
    }
}

// >= 1024 (Foundation == Large)
@media (min-width: 64em) {
    .locations-selector {
        ul {
            display: flex;
            justify-content: space-between;
            li {
                &.active,
                &:hover {
                    a:after {
                        bottom: -10px;
                    }
                }
            }
        }
    }
}
