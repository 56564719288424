.services-page {
    padding-top: 68px;
    h2.services--heading {
        margin-bottom: 32px;
        img {
            margin-top: -12px;
        }
    }
    &__cuts,
    &__shaves,
    &__extras,
    &__packages {
        &--heading {
            display: inline-block;
            margin-bottom: 30px;
            padding-right: 60px;
            position: relative;
        }
    }
    .medium-6,
    .medium-8 {
        h4 {
            text-align: left;
        }
    }
    &__cuts {
        &--heading {
            &:after {
                content: url(../img/icons/scissors.svg);
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    &__shaves {
        &--heading {
            &:after {
                content: url(../img/icons/zap.svg);
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    &__extras {
        &--heading {
            &:after {
                content: url(../img/icons/barbar-poll.svg);
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    &__packages {
        &--heading {
            &:after {
                content: url(../img/icons/package.svg);
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    h4 {
        color: #A5A4A8;
        font-family: 'tg-bold', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 24px;
        text-align: center;
        text-transform: uppercase;
    }
    &__title {
        color: $highlight;
        font-family: 'tg-bold', sans-serif;
        font-size: 32px;
        letter-spacing: 0.5px;
        line-height: 34px;
        text-transform: uppercase;
    }
    &__description {
        font-family: 'tg-light', sans-serif;
        letter-spacing: 0.25px;
        line-height: 22px;
        margin: 8px 0 30px 0;
    }
    &__cost {
        font-family: 'tg-bold', sans-serif;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
        &--barber {
            font-family: 'tg-bold', sans-serif;
            letter-spacing: 0.5px;
            line-height: 20px;
            text-align: center;
        }
        &--senior {
            font-family: 'tg-bold', sans-serif;
            letter-spacing: 0.5px;
            line-height: 20px;
            text-align: center;
        }
    }
    .services-page__package--notes {
        color: $highlight;
        font-family: 'tg-bold', sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.75px;
        line-height: 24px;
        margin-bottom: 74px;
        text-align: left;
    }
    &__extra-info {
        background-color: #F5F5F5;
        font-family: 'tg', sans-serif;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 32px;
        margin-bottom: 32px;
        padding: 22px 50px;
    }
    &__divider {
        border-bottom: 2px solid #D2D1D3;
        margin: 68px auto;
        max-width: 960px;
    }
}
