.nav-toggle {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5em;
    outline: none;
    padding: 40px 0;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1001;
}
.call {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5em;
    outline: none;
    padding: 40px 0;
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 1001;
}
.header {
    padding: 40px 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 100;
    &__logo {
        display: inline-block;
        height: auto;
        width: 164px;
        a {
            display: block;
            .uncle-logo {
                fill: #fff;
            }
        }
    }
    &__nav {
        background: rgba(0, 0, 0, 0.9);
        left: 0;
        min-height: 100vh;
        overflow: hidden;
        padding: .5em 0;
        position: absolute;
        transition: width .3s ease-out;
        top: -40px;
        width: 0;
        z-index: 1;
        &--primary {
            list-style-type: none;
            margin: 1em;
            padding: 0;
            li {
                a {
                    color: #fff;
                    display: block;
                    font-family: 'tg-bold', sans-serif;
                    font-size: 16px;
                    padding: 1em;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: color .3s ease-out;
                    &:hover,
                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }
        &.show-nav {
            width: 100vw;
        }
    }
    .wrapper {
        position: relative;
    }
}

// Position 'fixed' is a unicorn on mobile devices.
// Mobile requires 'absolute', so we need to figure out
// whether or not this is a mobile device WITHOUT
// device detection. So we narrow down the chances
// by detecting screen width / is touch enabled / and
// whether or not the primary pointer is fine or not.

// Pointer can be none, fine or coarse
// NOTE: Firefox as of 2019 STILL does not support
// (pointer: x) you STILL need to use a -moz prefix!
@media (min-width: 48em), (-moz-touch-enabled: 1) and (pointer: fine) {
    .nav-toggle,
    .call {
        display: none;
    }
    .header {
        position: fixed;
        text-align: left;
        transition: background-color .3s ease-out;
        z-index: 100;
        &__logo {
            display: inline-block;
            transition: padding .3s ease-out;
            width: 120px;
        }
        &__nav {
            background: transparent;
            display: inline-block;
            float: right;
            min-height: 0;
            padding: 2em 0;
            position: relative;
            top: 0;
            transition: padding .3s ease-out;
            width: auto;
            &--primary {
                display: inline-block;
                margin: .25em 0;
                li {
                    display: inline-block;
                    margin-left: 5px;
                    a {
                        padding: .25em 0;
                    }
                }
            }
        }
        &.on-scroll {
            background-color: rgba(255,255,255,1.0);
            box-shadow: 0 1px 12px 0 rgba(0,0,0,0.23);
            .header__logo {
                a {
                    .uncle-logo {
                         fill: #000;
                    }
                }
            }
            .header__nav {
                .header__nav--primary {
                    a {
                        color: $primary;
                    }
                }
            }
        }
    }
}

// >= 960
@media (min-width: 60em) {
    .header {
        &__logo {
            width: 164px;
        }
        &__nav {
            &--primary {
                display: inline-block;
                li {
                    margin-left: 20px;
                }
            }
        }
    }
}

// >= 1024 (Foundation == Large)
@media (min-width: 64em) {
    .header {
        &__nav {
            &--primary {
                display: inline-block;
                li {
                    margin-left: 25px;
                }
            }
        }
    }
}

// >= 1104
@media (min-width: 69em) {
    .header {
        &__nav {
            &--primary {
                display: inline-block;
                li {
                    margin-left: 30px;
                }
            }
        }
    }
}

// >= 1200
@media (min-width: 75em) {
    .header {
        &__nav {
            &--primary {
                display: inline-block;
                li {
                    margin-left: 49px;
                }
            }
        }
    }
}
