.contact {
    h2 {
        margin: 68px 0 32px 0;
    }
    &__sidebar {
        background-color: #F5F5F5;
        margin-top: 22px;
        padding: 0 51px;
        div {
            border-bottom: 2px solid #D2D1D3;
            padding: 51px 0;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
