.hero {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 130px;
    padding-top: 184px;
    position: relative;
    width: 100%;
    &:before {
        background: linear-gradient(315deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.7) 100%);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
    .wrapper {
        padding-bottom: 56px;
        position: relative;
        h1 {
          font-size: 36px;
          line-height: 1.2;
          @media (min-width: 40em) {
            font-size: 60px;
            line-height: 66px;
          }
        }
        .medium-10 {
          text-align: center;
          @media (min-width: 640px) {
            text-align: right;
          }

        }
    }
    @media (max-width: 639px) {
      text-align: center;
    }
}



// >= 1024 (Foundation == Large)
@media (min-width: 64em) {

    .hero {
        min-height: 580px;
    }
}
