/* Trade Gothic */
@font-face {
    font-family: 'tg';
    src: url('../fonts/trade-gothic/tg.woff') format('woff');
}
@font-face {
    font-family: 'tg-light';
    src: url('../fonts/trade-gothic/tg-light.woff') format('woff');
}
@font-face {
    font-family: 'tg-bold';
    src: url('../fonts/trade-gothic/tg-bold.woff') format('woff');
}

@font-face {
    font-family: 'tg-bold2';
    src: url('../fonts/trade-gothic/tg-bold2.woff') format('woff');
}
@font-face {
    font-family: 'tg-bold-ex';
    src: url('../fonts/trade-gothic/tg-bold-ex.woff') format('woff');
}



$rem-base           : 16px;    // Default font rem size

// Main site colors
$primary            : #000;    // black
$secondary          : #595959; // medium gray
$highlight          : #5C2946; // purple
